import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'pikaday/css/pikaday.css';
import './DatePicker.css';
import { Flex, Box } from 'rebass';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pl from 'date-fns/locale/pl';
import enGB from 'date-fns/locale/en-GB';
import uk from 'date-fns/locale/uk';
import de from 'date-fns/locale/de';
import es from 'date-fns/locale/es';
import { Field } from 'react-final-form';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';

import Dropdown from 'components/Dropdown';
import MainButton from 'components/MainButton';
import { BOOKING_HOURS } from 'utils/datetimeHelpers';
import {
  required,
  composeValidators,
  mustBe1hourDuration,
  mustBeLaterThanCurrentHour,
} from 'utils/formValidators';
import i18n from 'i18n';
import * as Styled from './style';
import FormSelect from '../FormSelect';
import Label from '../FormLabel';
import FormError from '../FormError';

registerLocale('pl', pl);
registerLocale('de', de);
registerLocale('ua', uk);
registerLocale('en', enGB);
registerLocale('es', es);

class DateTimePicker extends Component {
  state = {
    date: moment(),
  };

  componentDidMount = () => {
    const { formValues } = this.props;

    if (formValues) {
      const { date, from, to } = formValues;
      if (date && moment(date, 'DD.MM.YYYY').isValid() && from && to) {
        this.setState({
          date: moment(date, 'DD.MM.YYYY'),
        });
      }
    }
  };

  triggerOnChange = () => {
    const { input, changeFieldValueHandler } = this.props;
    const { date } = this.state;

    if (input) {
      input.onChange(moment(date).format('DD.MM.YYYY'));
    }
    if (changeFieldValueHandler) {
      changeFieldValueHandler('date', moment(date).format('DD.MM.YYYY'));
    }
  };

  handleDateChange = date => {
    this.setState({ date: moment(date) }, () => {
      this.triggerOnChange();
    });

    const isDateToday = moment(date).isSame(moment(), 'day');

    if (isDateToday) {
      const bookingHours = BOOKING_HOURS(7, 23, isDateToday).filter(
        ({ isDisabled }) => !isDisabled,
      );

      const {
        changeFieldValueHandler,
        formValues: { from },
      } = this.props;

      if (from) {
        const fromHour = moment(from, 'HH:mm');

        if (fromHour.isBefore(moment(), 'hour')) {
          const firstAvailableHour = bookingHours[0].value;

          changeFieldValueHandler('from', firstAvailableHour);
          this.handleStartTimeChange(firstAvailableHour);
        }
      }
    }
  };

  handleStartTimeChange = time => {
    const {
      changeFieldValueHandler,
      formValues: { to },
    } = this.props;

    const selected = moment(time, 'HH:mm');
    let ed = moment(to, 'HH:mm');

    let timeDifference = moment.duration(ed.diff(selected)).asHours();
    if (to === '00:00') timeDifference *= -1;
    if (timeDifference < 1) {
      ed = selected.add(1, 'hours');
      changeFieldValueHandler('to', ed.format('HH:mm'));
    }

    this.triggerOnChange();
  };

  handleEndTimeChange = time => {
    const {
      changeFieldValueHandler,
      formValues: { from },
    } = this.props;

    const selected = moment(time, 'HH:mm');
    let sd = moment(from, 'HH:mm');
    let timeDifference = moment.duration(selected.diff(sd)).asHours();
    if (timeDifference < 0 && time === '00:00') timeDifference *= -1;
    if (timeDifference < 1) {
      sd = selected.subtract(1, 'hours');
      changeFieldValueHandler('from', sd.format('HH:mm'));
    }

    this.triggerOnChange();
  };

  renderSelectedDateTime = () => {
    const {
      formValues: { from, to },
    } = this.props;
    const { date } = this.state;

    return `${moment(date).format('DD.MM.YYYY [/] ')}${from} - ${to}`;
  };

  render = () => {
    const { date } = this.state;
    const {
      hidePlaceholder,
      bgColor,
      bgTimeColor,
      placeholder,
      width,
      meta,
      t,
      withDropdown,
      close,
    } = this.props;

    const isDateToday = moment(date).isSame(moment(), 'day');

    const bookingHours = BOOKING_HOURS(7, 23, isDateToday);
    const checkoutHours = BOOKING_HOURS(8, 24, isDateToday, 1);

    const renderPickers = toggle => (
      <Styled.DateTimeWrapper>
        <Field
          name="day"
          render={props => (
            <DatePicker
              {...props.input}
              locale={`${i18n.language}`}
              dateFormat="dd.MM.yyyy"
              selected={date.toDate()}
              onChange={v => {
                this.handleDateChange(v);
                props.input.onChange(v);
              }}
              minDate={moment().toDate()}
              inline
            />
          )}
        />
        <Flex
          flexDirection="column"
          sx={{
            '@media screen and (min-width: 320px)': {
              width: '100%',
            },
            '@media screen and (max-width: 40em)': {
              marginTop: '-5px',
            },
          }}
        >
          <Styled.TimeBox bgTimeColor={bgTimeColor}>
            <Flex flexDirection="row">
              <Box textAlign="left" width={[1 / 2, 1 / 2, 1 / 2]}>
                <Field
                  width={1}
                  name="from"
                  label={t('common:From')}
                  component={FormSelect}
                  variant="primary"
                  options={bookingHours}
                  onChange={v => this.handleStartTimeChange(v)}
                  initialValue={bookingHours[0].value}
                  validate={composeValidators(
                    required,
                    mustBeLaterThanCurrentHour,
                  )}
                />
              </Box>
              <Styled.HoursSeparator>-</Styled.HoursSeparator>
              <Box textAlign="left" width={[1 / 2, 1 / 2, 1 / 2]}>
                <Field
                  width={1}
                  name="to"
                  label={t('common:To')}
                  component={FormSelect}
                  variant="primary"
                  options={checkoutHours}
                  onChange={v => this.handleEndTimeChange(v)}
                  initialValue={checkoutHours[0].value}
                  validate={composeValidators(required, mustBe1hourDuration)}
                />
              </Box>
            </Flex>
            <Styled.Line />
            <Label>{t('common:Current settings')}</Label>

            <Styled.SelectedTime>
              {this.renderSelectedDateTime()}
            </Styled.SelectedTime>

            <MainButton
              width="100%"
              onClick={() => {
                this.triggerOnChange();
                toggle();
              }}
              type="button"
            >
              Ok
            </MainButton>
          </Styled.TimeBox>
        </Flex>
      </Styled.DateTimeWrapper>
    );

    if (withDropdown) {
      return (
        <Styled.Wrapper width={width}>
          <Dropdown
            placeholder={placeholder}
            searchValue={!hidePlaceholder ? '' : this.renderSelectedDateTime()}
            bgColor={bgColor}
            bgTimeColor={bgTimeColor}
          >
            {({ toggle }) => renderPickers(toggle)}
          </Dropdown>
          <FormError meta={meta} bottom={0} />
        </Styled.Wrapper>
      );
    }

    return (
      <Styled.Wrapper width={width}>{renderPickers(close)}</Styled.Wrapper>
    );
  };
}

DateTimePicker.defaultProps = {
  withDropdown: true,
};

DateTimePicker.propTypes = {
  t: PropTypes.func.isRequired,
  changeFieldValueHandler: PropTypes.func.isRequired,
  withDropdown: PropTypes.bool,
};

export default withTranslation()(DateTimePicker);
